import React, { Component } from 'react';
import styles from './FixedLeftNav.module.scss'

const SecondaryNavInternal = ({href, Icon, title, Component, label}) => <Component to={href} className={styles['secondary-link']} activeClassName={styles['secondary-link-selected']}>
    {Icon && <Icon/>}
    <span>{title}</span>
    {label && <Label text={label}/>}
</Component>;

const SecondaryExternalNav = ({linkKey, Icon, title, Component, NavIcon, label}) => <Component className={styles['secondary-link']} linkKey={linkKey} newTab={true}>
    {Icon && <Icon/>}
    <span>{title}</span>
    <NavIcon className={styles['secondary-external-icon']}/>
    {label && <Label text={label}/>}
</Component>;


const NavDropDown = ({Icon, title, onMouseOver, NavIcon}) => <button className={styles['link']} onMouseOver={onMouseOver}>
    <Icon/>
    <span>{title}</span>
    <NavIcon className={styles['drop-down-icon']}/>
</button>;

const NavExternal = ({linkKey, Icon, title, Component, NavIcon, label}) => <Component className={styles['link']} linkKey={linkKey} newTab={true}>
    <Icon/>
    <span>{title}</span>
    <NavIcon className={styles['external-icon']}/>
    {label && <Label text={label}/>}
</Component>;

const NavInternal = ({to, Icon, title, Component, label}) => <Component to={to} className={styles['link']} activeClassName={styles['link-selected']}>
    <Icon/>
    <span>{title}</span>
    {label && <Label text={label}/>}
</Component>;

const NavAction = ({Icon, title, onClick, label}) => <button className={`${styles['link']}`} onClick={onClick}>
    <Icon/>
    <span>{title}</span>
    {label && <Label text={label}/>}
</button>;

const Label = ({text}) => <div className={styles['label']}>{text}</div>;

export default class extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render () {
        const {mainLinks, InternalLink, ExternalLink, HeaderButton, ArrowIcon, ExternalLinkIcon} = this.props;
        return <div onMouseLeave={() => {
            this.setState({openDropDown: null})
        }}>
            <nav className={styles['base']}>
                <div className={styles['header']}>
                    <HeaderButton/>
                </div>
                <div className={styles['links']}>
                    {mainLinks.map(({isExternalLink, isDropdown, isAction, href, linkKey, Icon, title, onClick, links, label}) => {

                        return <div key={title} onMouseOver={(el) => {
                            this.setState({openDropDown: null});
                        }}>
                            {isDropdown && <NavDropDown NavIcon={ArrowIcon} Icon={Icon} title={title} links={links} key={title} onMouseOver={(el) => {
                                el.stopPropagation();
                                if(this.state.openDropDown !== links) {
                                    this.setState({openDropDown: links, dropDownTop: el.currentTarget.getBoundingClientRect().top});
                                }
                            }}/>}

                            {isAction && <NavAction label={label} Icon={Icon} title={title} onClick={onClick} key={title} onMouseOver={(el) => {
                                this.setState({openDropDown: null})
                            }}/>}

                            {isExternalLink && <NavExternal label={label} NavIcon={ExternalLinkIcon} Component={ExternalLink} linkKey={linkKey} Icon={Icon} title={title} key={title} onMouseOver={(el) => {
                                this.setState({openDropDown: null})
                            }}/>}

                            {!isDropdown && !isAction && !isExternalLink && <NavInternal label={label} Component={InternalLink} to={href} Icon={Icon} title={title} key={title} onMouseOver={(el) => {
                                this.setState({openDropDown: null})
                            }}/>}
                        </div>
                    })}
                </div>
            </nav>
            {this.state.openDropDown && <nav className={styles['secondary-nav']} style={{top: this.state.dropDownTop, maxHeight: `calc(100% - ${this.state.dropDownTop}px)`}}>
                {this.state.openDropDown.map(({isExternalLink, Icon, title, href, linkKey, label}) => {
                    if(isExternalLink){
                        return <SecondaryExternalNav label={label} NavIcon={ExternalLinkIcon} Component={ExternalLink} Icon={Icon} title={title} linkKey={linkKey} key={title}/>
                    } else {
                        return <SecondaryNavInternal label={label} Component={InternalLink} Icon={Icon} title={title} href={href} key={title}/>
                    }
                })}
            </nav>}
        </div>;
    }
}